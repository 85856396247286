import { Line, mixins } from 'vue-chartjs';
import chartjsPluginAnnotation from 'chartjs-plugin-annotation';

const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    zoomUpdated: {
      default: 0,
      required: false,
    },
    chartData: {
      required: false,
      type: Object,
    },
    options: {
      required: false,
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        scaleBeginAtZero: false,
      }),
    },
  },
  mounted() {
    // @ts-ignore
    Chart.plugins.register(chartjsPluginAnnotation);
    // @ts-ignore
    this.addPlugin(chartjsPluginAnnotation);
    // @ts-ignore
    this.renderChart(this.chartData, this.options);
  },

  beforeDestory() {
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    if(this.$data && this.$data._chart){
      // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
      this.$data._chart.destory();
    }

  },

  watch: {


    yAxisRange(val) {
       // @ts-ignore
      this.renderChart(this.chartData, this.options);
    },



    zoomUpdated(val) {
      // @ts-ignore
     this.renderChart(this.chartData, this.options);
   },
  },
};
